import React, { useState, useEffect } from "react";
import Teams from "./teams";
import ChangePassword from "./changePasword";
import NotFound from "./notFound";
import MyTasks from "./myTasks";
import Evaluations2 from "./evaluations";
import DashboardM from "./dashboardM";
import Dashboard from "./dashboardP";
import AdminUsers from "./adminUsers";
import ProfileView from "./vistaPerfil";
import Help from "./help";
import NavBar from "./../components/navbar";
import { Route, Switch, useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import { exposPerms, rootEndpoint, scholarshipGeneralPerms } from "./../utils/constants";
import Loading from "../components/loading";
import NewCycle from "./newCycle";
import DatabaseTH from "./databaseTH";
import Events from "./eventsf";
import Reports from "./newReports";
import StartPageMobile from "./startPageMobile";
import useWindowDimensions from "../utils/useWindowDimensions";
import { widthMobile } from "./../utils/constants";
import { message } from "antd";
import ProfileViewMobile from "./profileViewM";
import EditEvaluations from "./editEvaluations";
import EvaluationAnswers from "./evaluationAnswers";
import EvaluationReport from "./evaluationReport";
import server, { endpoints } from "../utils/server";
import Workshops from "./workshops";
import EvaluaionsDataBase from "./evaluationsDataBase";
import AttendanceReport from "./attendanceReport";
import Scholarships from "./scholarships";
import ScolarShipDownload from "./katiaScholarshipDownload";
import ExposEvents from "./expos/exposEvents";
import ExpoDetails from "./expos/eventDetails";

//* Components
// import personalInfo from 'src\components\MainPage\personalInformation.jsx';
import StudentScholarshipInfo from "../components/studentScholarshipInfo";
import ScholarshipGeneralInfo from "../components/scholarshipGeneralInfo";
import ScholarshipReport from "../components/scholarshipReport";

const MainPage = (props) => {
  const [sessionReceived, setSessionReceived] = useState(false);
  const [session, setSession] = useState({});
  const [cycles, setCycles] = useState([]);
  const [teams, setTeams] = useState({});
  const [otherTeams, setOtherTeams] = useState({});
  const [teamsAndCyclesPerms, seTeamsAndCyclesPerms] = useState(false);
  const [userCanDeleteAndCreateTeams, setUserCanDeleteAndCreateTeams] =
    useState(false);
  const [profileStateM, setProfileStateM] = useState("profile");
  const { width } = useWindowDimensions();
  const IsMobile = width < widthMobile;
  const { pathname } = useLocation();
  const renderNavBarMobile = !["/profileView"].includes(pathname);
  useEffect(() => {
    getSessionInfo();
    getTeams();
  }, []);

  const getSessionInfo = () => {
    server(endpoints.getSessionInfo.route, endpoints.getSessionInfo.method, {
      id: props.idUR,
    }).then((res) => {
      const { error_message } = res;
      if (error_message) {
        message.error(error_message);
        return;
      }
      let cycles = res.data.cycles;
      let currentCycleID = 0;
      if (cycles) {
        currentCycleID = cycles.filter((c) => c.is_current === true)[0].id;
      }
      if (props.idSelectedCycle === 0) {
        props.onCycleChange(currentCycleID);
      } else {
        setSession(res.data);
        setCycles(cycles);
        handleSessionReceived();
      }
    });
  };

  const getTeams = async () => {
    const { idSelectedCycle } = props;
    if (idSelectedCycle === 0) {
      return;
    }
    const params = {
      id_cycle: idSelectedCycle,
    };
    const res = await server(
      endpoints.getTeamsOfUser.route,
      endpoints.getTeamsOfUser.method,
      params
    );
    const { error_message, data } = res;
    if (error_message) {
      message.error(error_message);
    } else {
      setTeams(data.userTeams);
      setOtherTeams(data.otherTeams);
      setUserCanDeleteAndCreateTeams(data.userCanDeleteAndCreateTeams);
    }
  };
  const refreshTeams = () => {
    getTeams();
  };

  const refreshAllPendingTaskBadges = () => {
    getSessionInfo();
  };

  const handleSessionReceived = () => {
    setSessionReceived(true);
  };

  const currentCycleChanged = (idNewCycle) => {
    const newCycles = cycles.map((cycle) => ({
      ...cycle,
      is_current: cycle.id === idNewCycle,
    }));
    setCycles(newCycles);
  };

  const onProfilePhotoChanged = (newFileUrl) => {
    /*aquí no hay necesidad de ponerlo entre corchetes, porque el nombre de la propiedad es profile_photo_url
    se pone entre corchetes cuando la variable es un string que contiene el nombre de la propiedad como const property="profile_photo_url"
    setSession({
      ...session,
      [profile_photo_url]: newFileUrl
    });
    */
    setSession({
      ...session,
      profile_photo_url: newFileUrl,
    });
  };

  /*const onCycleCreated = () => {
    getSessionInfo();
  }*/

  const { idUR, logOut, idSelectedCycle, onCycleChange } = props;
  return (
    <div>
      {sessionReceived ? (
        <div>
          {!IsMobile ? (
            <NavBar
              logOut={logOut}
              session={session}
              cycles={cycles}
              idUR={idUR}
              idSelectedCycle={idSelectedCycle}
              onCycleChange={onCycleChange}
              currentCycleChanged={currentCycleChanged}
            />
          ) : (
            renderNavBarMobile && (
              <StartPageMobile
                session={session}
                logOut={logOut}
                setProfileState={setProfileStateM}
              />
            )
          )}
          <div className="content m-3" style={{ height: "100%" }}>
            <Switch>
              <Route path="/not-found" exact component={NotFound} />
              <Route
                path="/changePassword"
                exact
                component={() => <ChangePassword isMobile={IsMobile} />}
              />
              <Route
                path="/profileView"
                exact
                render={() =>
                  !IsMobile ? (
                    <ProfileView
                      idUR={idUR}
                      idSelectedCycle={idSelectedCycle}
                      onProfilePhotoChanged={onProfilePhotoChanged}
                      getSessionInfo={getSessionInfo}
                    />
                  ) : (
                    <ProfileViewMobile
                      session={session}
                      profileState={profileStateM}
                      setProfileState={setProfileStateM}
                      idCycle={idSelectedCycle}
                      idUR={idUR}
                    />
                  )
                }
              />
              <Route
                path="/myTasks"
                exact
                render={() => (
                  <MyTasks
                    idSelectedCycle={idSelectedCycle}
                    teams={teams}
                    otherTeams={otherTeams}
                    idUR={idUR}
                    session={session}
                    refreshAllPendingTaskBadges={refreshAllPendingTaskBadges}
                  />
                )}
              />
              <Route
                path="/events"
                exact
                render={() =>
                  IsMobile ? (
                    <div>
                      <Events idCycle={idSelectedCycle} idUR={idUR} />
                    </div>
                  ) : (
                    <Events idCycle={idSelectedCycle} idUR={idUR} />
                  )
                }
              />

              <Route
                path="/dashboard"
                exact
                render={() =>
                  !IsMobile ? (
                    <Dashboard
                      idUR={idUR}
                      idCycle={idSelectedCycle}
                      teams={teams}
                      otherTeams={otherTeams}
                      profileState={profileStateM}
                      setProfileState={setProfileStateM}
                    />
                  ) : (
                    <DashboardM
                      idUR={idUR}
                      idCycle={idSelectedCycle}
                      teams={teams}
                      otherTeams={otherTeams}
                      profileState={profileStateM}
                      setProfileState={setProfileStateM}
                    />
                  )
                }
              />
              <Route
                path="/reports"
                exact
                render={() => (
                  <Reports
                    idUR={idUR}
                    idCycle={idSelectedCycle}
                    adminPerms={session.adminPerms}
                  />
                )}
              />
              <Route
                path="/evaluations"
                exact
                render={() => (
                  <Evaluations2
                    idUR={idUR}
                    teams={teams}
                    idCycle={idSelectedCycle}
                    adminPerms={session.adminPerms}
                  />
                )}
              />
              {/* <Route
                path="/evaluationDataBase"
                exact
                render={() => (
                  <EvaluaionsDataBase
                    idUR={idUR}
                    teams={teams}
                    idCycle={idSelectedCycle}
                    adminPerms={session.adminPerms}
                  />
                )}
              /> */}
              {session.workshopPerms === true ? (
                <Route
                path="/workshops"
                exact
                render={() => (
                  <Workshops
                  idUR={idUR}
                  idCycle={idSelectedCycle}
                  adminPerms={session.adminPerms}
                  />
                )}
                />
              ): null}
              {exposPerms(idUR) ?
                <Route
                path="/exposEvents"
                exact
                render={() => (
                  <ExposEvents idCycle={idSelectedCycle} idUR={idUR} />
                )}
                /> 
                :null}
                {exposPerms(idUR) ?
                <Route 
                path="/exposEvents/:id"
                exact
                render={() => (
                  <ExpoDetails/>
                )}
                />
                :null}
              <Route
              path="/teams"
                exact
                render={() => (
                  <Teams
                    idSelectedCycle={idSelectedCycle}
                    teams={teams}
                    otherTeams={otherTeams}
                    idUR={idUR}
                    refreshTeams={refreshTeams}
                    userCanDeleteAndCreateTeams={userCanDeleteAndCreateTeams}
                    refreshAllPendingTaskBadges={refreshAllPendingTaskBadges}
                    getTeams={getTeams}
                  />
                )}
              />
              {session.adminPerms === true ? (
                <Route
                  path="/adminUsers"
                  exact
                  render={() => <AdminUsers idUR={idUR} />}
                />
              ) : null}

              {session.dataBasePerms === true ? (
                <Route
                  path="/databaseTH"
                  exact
                  render={() => (
                    <DatabaseTH idUR={idUR} idSelectedCycle={idSelectedCycle} />
                  )}
                />
              ) : null}
              {session.teamsAndCyclesPerms === true ? (
                <Route
                  path="/attendanceDatabase"
                  exact
                  render={() => (
                    <AttendanceReport
                      idUR={idUR}
                      idSelectedCycle={idSelectedCycle}
                    />
                  )}
                />
              ) : null}
              {session.evaluationPerms === true ? (
                <Route
                  path="/editEvaluations"
                  exact
                  render={() => (
                    <EditEvaluations
                      idUR={idUR}
                      idCycle={idSelectedCycle}
                      teams={teams}
                    />
                  )}
                />
              ) : null}
              {session.scholarshipPerms === true ? (
                <Route
                  path="/scholarships"
                  exact
                  render={() => (
                    <Scholarships
                      idUR={idUR}
                      idCycle={idSelectedCycle}
                      teams={teams}
                    />
                  )}
                />
              ) : null}
              {session.scholarshipPerms === true ? (
                <Route
                  path="/studentScholarshipInfo/:id"
                  exact
                  render={() => (
                    <StudentScholarshipInfo
                      idUR={idUR}
                      idCycle={idSelectedCycle}
                      teams={teams}
                    />
                  )}
                />
              ) : null}
              {session.scholarshipPerms === true ? (
                <Route
                  path="/studentScholarshipInfo/:id/report"
                  exact
                  render={() => <ScholarshipReport idCycle={idSelectedCycle} />}
                />
              ) : null}
              {session.scholarshipPerms === true &&
              scholarshipGeneralPerms(idUR) ? (
                <Route
                  path="/scholarshipGeneralInfo"
                  exact
                  render={() => (
                    <ScholarshipGeneralInfo
                      idUR={idUR}
                      idCycle={idSelectedCycle}
                      teams={teams}
                    />
                  )}
                />
              ) : null}
              {session.evaluationPerms === true &&
              scholarshipGeneralPerms(idUR) ? (
                <Route
                  path="/katiaScholarshipDownload"
                  exact
                  render={() => (
                    <ScolarShipDownload
                      idCycle={idSelectedCycle}
                      id_UR={idUR}
                    />
                  )}
                />
              ) : null}
              {session.evaluationPerms === true ? (
                <Route
                  path="/evaluationAnswers/:id"
                  exact
                  render={() => (
                    <EvaluationAnswers idUR={idUR} idCycle={idSelectedCycle} />
                  )}
                />
              ) : null}
              {session.evaluationPerms === true ? (
                <Route
                  path="/evaluationReport/:id"
                  exact
                  render={() => (
                    <EvaluationReport idUR={idUR} idCycle={idSelectedCycle} />
                  )}
                />
              ) : null}
              
              
              <Route
                path="/help"
                exact
                render={() => <Help idUR={idUR}></Help>}
              />
              <Route
                path="/create-cycle"
                exact
                render={() => (
                  <NewCycle
                    idSelectedCycle={idSelectedCycle}
                    teams={teams}
                    otherTeams={otherTeams}
                    idUR={idUR}
                    onCycleCreated={getSessionInfo()}
                  />
                )}
              />
              {/* <Redirect from="/" exact to="/myTasks" /> */}
              {/* <Redirect to="/not-found" /> */}
            </Switch>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default MainPage;
