import React, { useState, useEffect } from "react";
import {
  Button,
  Row,
  Modal,
  Typography,
  Select,
  Input,
  message,
  Divider,
  Popconfirm,
} from "antd";

import { ArrowLeftOutlined, MailOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import server, { endpoints } from "../utils/server";
import Flecha_izquierda from "../resources/Flecha_izquierda_s_c.png";
import Gmail_icon from "../resources/gmail_icon.png";
import TableBecas from "../components/Becas/TableBecas.jsx";
import Flecha_derecha from "../resources/Flecha_derecha.png";
import Descargar_icon from "../resources/descargar_icon.png";
import "./styles/katia_becas.css";

const { Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const ScolarShipDownload = ({ idCycle, id_UR }) => {
  const [exportarTabla, setExportarTabla] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [messageText, setMessageText] = useState("");

  const insertScholarshipMessage = async () => {
    const res = await server(
      endpoints.insertScholarshipMessage.route,
      endpoints.insertScholarshipMessage.method,
      { id_cycle: idCycle.idCycle },
      { message: messageText }
    );
    if (res.error_message) {
      message.error(res.error_message);
    } else {
      message.success(res.message);
    }
  };

  const handleMessageModalOpen = () => {
    setIsMessageModalOpen(!isMessageModalOpen);
  };

  const handleMessageModalCancel = () => {
    setIsMessageModalOpen(!isMessageModalOpen);
  };

  const handleMessageModalOk = () => {
    insertScholarshipMessage();
    setIsMessageModalOpen(!isMessageModalOpen);
    setMessageText("");
  };

  return (
    <div className="container">
      
      <Row
        style={{
          display: "flex",
          alignItems: "baseline",
          alignContent: "center",
          paddingTop: "1%",
          paddingLeft: "1%",
        }}
      >
        <NavLink to="scholarshipGeneralInfo">
          <Button icon={<ArrowLeftOutlined />} />
        </NavLink>
        <h4 style={{ paddingLeft: "1%" }}>Regresar al menú</h4>
      </Row>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          gridTemplateAreas: `"a b c"`,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Title
          level={2}
          style={{
            gridArea: "b",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            paddingTop: "20px",
          }}
        >
          General
        </Title>

        {/* <Button
          onClick={handleMessageModalOpen}
          shape="circle"
          icon={<MailOutlined />}
          style={{
            gridArea: "c",
            backgroundColor: "#1892ff",
            color: "#FFFFFF",
          }}
        /> */}
      </div>
      <div
        style={{
          width: "80%",
          display: "flex",
          justifyContent: "center",
          margin: "0 auto",
        }}
      >
        <Divider
          style={{
            width: "100%",
            height: "0.8px",
            backgroundColor: "#000000",
          }}
        />
      </div>

      <div className="Middle_div">
        <div className="Info_container">
          <h4>Becas en Sistema</h4>
          <p>Becas disponibles en equipo</p>
        </div>
        <div className="Buttons_container">
          <div className="Buttons">
            {/* <button className="button">
              <img className="flecha_derecha" src={Flecha_derecha}></img> Mover
              de ciclo
            </button> */}
            {/* <Popconfirm
              title={<span>¿Seguro que desea guardar las becas en ciclo? <br /> Esto hará que no puedas modificar las becas más.</span>}
              okText="Si"
              cancelText="Cancelar"
              onConfirm= {()=>{ alert("Aqui deberia estar la función.")}}
            >
               <button
                className="button"
              >
                Guardar becas en ciclo
              </button>
            </Popconfirm> */}
           
            <button
              className="button"
              onClick={() => {
                setExportarTabla(!exportarTabla);
              }}
            >
              <img className="descargar" src={Descargar_icon}></img> Exportar
            </button>
          </div>
        </div>
      </div> 
<div  className="Table_second_container">

      <div className="Table_container">
        <TableBecas
          idCycle={idCycle}
          exportarTabla={exportarTabla}
          idUR={id_UR}
        ></TableBecas>
      </div>
      </div>
      <Modal
        open={isMessageModalOpen}
        onOk={handleMessageModalOk}
        onCancel={handleMessageModalCancel}
        title="Enviar aviso"
      >
        <Title level={4}>Mensaje:</Title>
        <TextArea
          placeholder="Agregar aviso"
          rows={4}
          onChange={(e) => {
            setMessageText(e.target.value);
          }}
        />
      </Modal>
    </div>
  );
};

export default ScolarShipDownload;
