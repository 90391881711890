const testsServerEnabled = false;
const inUPServer = false;

export const rootEndpoint = () => {
    var rootEndpoint;
    if (inUPServer) {
        rootEndpoint = "https://sistemayc.gdl.up.mx:3001/api";
        if (testsServerEnabled) {
            rootEndpoint = "https://sistemayc.gdl.up.mx:3000/";
        }
    }
    else {
        rootEndpoint = "https://aycsite.up.edu.mx:3001/";
        if (testsServerEnabled) {
            rootEndpoint = "https://aycsite.up.edu.mx:3000/";
        }
    }
    return rootEndpoint;
}

export const filesSizeLimitMB = "10";

export const widthMobile = 750;

export const scholarshipGeneralPerms = (idUR) =>{
    return (idUR === 14 || idUR === 21 || idUR === 489 || idUR === 400)
}

export const exposPerms = (idUR) =>{
    return (idUR===14 || idUR === 544);
}